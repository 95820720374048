<script setup lang="ts">
import Button from '../design-system/button.vue';
import { CheckIcon } from '@heroicons/vue/24/outline';
import { globalKeys } from 'assets/data/global-keys';
const cookieBar: globalThis.Ref<boolean> = ref(false);
const { $storageService } = useServices();

function setCookie(toggle: boolean) {
  $storageService.setCookie({
    key: globalKeys.siteConfigKeys.acceptCookies,
    data: String(toggle),
    expiry: 30,
  });
  cookieBar.value = false;
}

function checkCookieStatus() {
  let cookieStr: string = $storageService.getCookie({
    key: globalKeys.siteConfigKeys.acceptCookies,
  });
  if (typeof cookieStr === 'undefined') {
    setCookie(false);
    cookieBar.value = true;

    return;
  }
  cookieBar.value = String(cookieStr).toLowerCase() !== 'true';
}

onMounted(() => {
  checkCookieStatus();
});
</script>

<template>
  <div
    v-if="cookieBar"
    class="bg-base text-base-priority fixed bottom-0 left-0 right-0 mb-3 p-4 cookie-container rounded-xl bg-base-border shadow-md"
    style="z-index: 20"
  >
    <div class="font-bold mb-1 capitalize">
      {{ $t('cookie-policy') }}
    </div>
    <div class="mb-1">
      {{ $t('cookie-message') }}
      <a href="/privacy-policy" class="whitespace-nowrap no-underline">
        <span class="text-blue-500 my-0">{{ $t('cookie-policy') }}.</span>
      </a>
    </div>
    <div class="flex flex-col-reverse sm:flex-row sm:px-0 md:mt-2 mt-4 gap-2">
      <Button
        :label="$t('accept-all')"
        padding="wide"
        class="justify-center"
        @click="setCookie(true)"
      >
        <CheckIcon />
        {{ $t('accept-all') }}
      </Button>
      <Button
        type="tertiary-alternative"
        padding="wide"
        class="justify-center"
        @click="setCookie(false)"
      >
        {{ $t('reject-all') }}
      </Button>
    </div>
  </div>
</template>

<style scoped>
.cookie-container {
  margin: auto;
  width: 90%;
  display: inline-block;
}
</style>
